<template>
    <div class="d-flex flex-column flex-shrink-0 p-3 bg-light overflow-auto sidebar-container">
        <ul class="nav nav-pills flex-column mb-auto gap-1">
            <li class="nav-item" @click="routeChanged()" v-for="(menu, index) in menus" :key="menu.name">
                <router-link style="display: flex;" :id="`${menu.name}-${index}`" :to="menu.link" class="nav-link"
                    aria-current="page" :class="{
                active: $route.path.startsWith(menu.link) && (menu.link !== '/' || $route.path === '/'),
            }">
                    <i :class="menu.icon" class="me-2"></i>
                    {{ menu.name }}
                </router-link>
            </li>
        </ul>
    </div>
</template>

<script>
import { Auth } from "aws-amplify";

export default {
    name: "LeftSidebar",
    props: ["changeSidebar", "showSidebar"],
    data() {
        return {
            menus: [
                {
                    name: "Dashboard",
                    icon: "bi bi-speedometer2",
                    link: "/",
                },
                {
                    name: "Templates",
                    icon: "bi bi-columns",
                    link: "/templates",
                },
                {
                    name: "Campaigns",
                    icon: "bi bi-activity",
                    link: "/campaigns",
                },
                {
                    name: "Leads",
                    icon: "bi bi-award-fill",
                    link: "/leads",
                },
                {
                    name: "Launch",
                    icon: "bi bi-rocket-takeoff",
                    link: "/launch",
                },
                {
                    name: "History",
                    icon: "bi bi-clock-history",
                    link: "/history",
                },
                {
                    name: "Identity",
                    icon: "bi bi-person-vcard",
                    link: "/identity",
                },
                {
                    name: "Integration",
                    icon: "bi bi-x-diamond-fill",
                    link: "/integration",
                },
                {
                    name: "Widgets",
                    icon: "bi bi-menu-button-wide",
                    link: "/widgets",
                },
                {
                    name: "Trash",
                    icon: "bi bi-recycle",
                    link: "/trash",
                },
                {
                    name: "Support",
                    icon: "bi bi-headset",
                    link: "/support",
                },

            ],
            user: {},
        };
    },
    methods: {
        routeChanged() {
            if (this.showSidebar) {
                this.changeSidebar();
            }
        },
        isDev() {
            return process.env.VUE_APP_Environment === "dev";
        },
    },
    created() {
        Auth.currentUserInfo().then((user) => {
            this.user = user;
        });
    },
};
</script>

<style>
.nav-link {
    color: #828282 !important;
    font-weight: 500 !important;
    margin: 0px !important;
    font-size: 14px !important;
    padding: 7px 12px !important;
    font-weight: normal !important;
    width: 100%;
    display: flex;
    align-items: center;
    border-radius: 0.375rem;
    transition: background-color 0.3s ease, color 0.3s ease, transform 0.2s ease;
}

.nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
    background: none !important;
    color: #5a4fdc !important;
    font-weight: bolder;
}


.sidebar-container {
    background-color: #f8f9fa;
    height: 100%;
}

.nav-item {
    width: 100%;
}

.nav-link:hover {
    background-color: #e2e6ea !important;
    color: #495057 !important;
}

.nav-pills .nav-link.active i,
.nav-pills .show>.nav-link i {
    color: #5a4fdc !important;
}

.nav-pills .nav-link:hover i {
    color: #495057 !important;
}
.nav-pills .nav-link.active:hover{
    background-color: #e2e6ea !important;
}
</style>
